
import {
    defineComponent, onBeforeMount, reactive,
} from 'vue';
import coreStore from '@/store/CoreStore';
import router from '@/router';
import CoreRouteTypes from '@/router/types';
import LanguageService from '@/services/LanguageService';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import Location from '@/domain/Location';

type State = {
    isDisabled: boolean;
    location: Location | undefined;
};

export default defineComponent({
    name: 'location-selector-screen',
    components: { DropdownAutocompleteSingleSelect },
    setup() {
        const { profileStore } = coreStore.getInstance();

        const state = reactive<State>({
            isDisabled: false,
            location: undefined,
        });

        async function setCurrentLocation(locationId: number) {
            await profileStore.setUserLocation(locationId);
            LanguageService.initDefaultLanguage();
            await router.push({ name: CoreRouteTypes.HOME });
        }

        onBeforeMount(async () => {
            if (profileStore.locations.length === 1) {
                await setCurrentLocation(profileStore.locations[0].id);
            }
        });

        async function doSetCurrentLocation() {
            state.isDisabled = true;
            if (state.location) {
                await setCurrentLocation(state.location.id);
            }

            state.isDisabled = false;
        }

        function selectLocation(loc: Location | undefined) {
            if (loc !== undefined && loc?.id > 0) {
                state.location = loc;
            }
        }

        function clearLocation() {
            state.location = undefined;
        }

        return {
            state,
            doSetCurrentLocation,
            profileStore,
            getTitleCaseTranslation,
            selectLocation,
            clearLocation,
        };
    },
});
