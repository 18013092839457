import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-447a792b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "location-container"
}
const _hoisted_2 = { class: "location-select-container" }
const _hoisted_3 = { class: "location-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_ctx.profileStore.isProfileLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.selectCurrentLocation')), 1),
          _createVNode(_component_b_form, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_dropdown_autocomplete_single_select, {
                  "model-value": _ctx.state.location?.name,
                  data: _ctx.profileStore.locations,
                  "search-by": "name",
                  "display-name": "name",
                  "select-on-enter": "",
                  onOnSelect: _ctx.selectLocation,
                  onOnClear: _ctx.clearLocation
                }, null, 8, ["model-value", "data", "onOnSelect", "onOnClear"])
              ]),
              _createVNode(_component_b_button, {
                disabled: !_ctx.state.location?.id,
                variant: "primary",
                class: "btn-location-select",
                onClick: _ctx.doSetCurrentLocation
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_b_spinner, { class: "location-select-spinner" }, null, 512), [
                    [_vShow, _ctx.state.isDisabled]
                  ]),
                  _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 513), [
                    [_vShow, !_ctx.state.isDisabled]
                  ])
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}